import React, { useState } from 'react';
import Box from '@mui/material/Box';
// import { Stack, Typography, Select, MenuItem } from "@mui/material";
// import StyleIcon from "@mui/icons-material/Style";
import { useDCS } from './DCSContext'; // Import the custom hook
// import RowEditableTable from '../../../../../../components/Tables/RowEditableTable';
import RowEditableTable from '../../../../../../common/components/table/RowEditableTable';
import UserDialog from './UsersDialog';
// import ForgeViewerModal from './ViewerModal';
import TimesheetDialog from '../../../../../../common/components/dialogs/TimesheetDialog';
// import CommentsDialog from '../../../../../../common/components/dialogs/CommentsDialog';
import RaciDashboard from './Dashboard';
import DashboardOutlinedIcon from '@mui/icons-material/DashboardOutlined';
import NewRowModal from './NewRaciModal';
import { AuthContext } from '../../../../../../common/contexts/authContext';
import RACIColumns from './RaciColumns';
import NewVersionModal from './CreateVersionModal';
// import CommentsDialog from './comments/CommentsDialog';
import CommentsDialog from '../../../../components/CommentsDialog';
// import { RACIColumns } from './utils';

export default function RACI({ type }) {
  const { auth, logout } = React.useContext(AuthContext);
  const currentUser = auth;
  const {
    racis,
    allUsers,
    sharepointDocuments,
    urnLoaded,
    addRaci,
    updateRaciData,
    getTimesheetsData,
    createTimesheetData,
    updateTimesheetData,
    deleteTimesheetData,
    getCommentsData,
    createCommentData,
    documentList,
    addVersion,
  } = useDCS(); // Use the DCSContext

  // Dialog state management
  const [dialogState, setDialogState] = useState({
    mail: false,
    userDialog: false,
    timesheet: false,
    preview: false,
    comments: false,
  });
  const [selectedItem, setSelectedItem] = useState(null);
  const [selectedOption, setSelectedOption] = useState('2D');
  const [newRowModalState, setNewRowModalState] = useState(false);
  const [newVersionModalState, setNewVersionModalState] = useState(false);
  const [currentRaciId, setCurrentRaciId] = useState(null);

  const [modalType, setModalType] = useState('acc');

  const handleButtonClick = (type, row) => {
    setSelectedItem(row.original);
    if (type === 'acc' || type === 'cons' || type === 'inf') {
      setModalType(type);
      setDialogState((prev) => ({ ...prev, userDialog: true }));
    } else {
      setDialogState((prev) => ({ ...prev, [type]: true }));
    }
  };

  const handleDialogClose = () => {
    setDialogState({
      mail: false,
      userDialog: false,
      timesheet: false,
      preview: false,
      comments: false,
    });
  };

  const handleSaveRow = async (values) => {
    console.log(values);

    const newValues = {
      document: values.document,
      docLink: values.docLink,
      revNum: values.revNum,
      responsible: values.responsible,
      type: type,
      docNum: values.docNum,
    };
    await addRaci(newValues);
  };
  const handleCreateVersion = async (values) => {
    console.log(values);
    await addVersion(currentRaciId, values);
  };

  const handleCreateRow = () => {
    addRaci({
      document: '',
      revNum: '',
      docLink: '',
      responsible: '',
      type: type,
      isNew: true,
    });
  };

  return (
    <Box
      sx={{ width: '100%', typography: 'body1', overflow: 'auto', padding: 2 }}
    >
      <RaciDashboard documents={racis} type={type} />

      {/* <RowEditableTable
        columns={RACIColumns({
          allUsers,
          handleButtonClick: handleButtonClick,
          // currentUser,
          sharepointDocuments,
          urnLoaded,
        })}
        data={racis}
        handleCreateRow={() => {
          setNewRowModalState(true);
        }}
        // handleSaveRow={handleSaveRow}
        additionalProps={{ positionGlobalFilter: "left" }}
      /> */}
      <RACIColumns
        handleButtonClick={handleButtonClick}
        allUsers={allUsers}
        currentUser={currentUser}
        openVersionNewModal={(rowId) => {
          setCurrentRaciId(rowId);
          setNewVersionModalState(true);
        }}
        openNewRaciModal={() => {
          setNewRowModalState(true);
        }}
      />

      <UserDialog
        open={dialogState.userDialog}
        handleClose={handleDialogClose}
        rowId={selectedItem?.id}
        allUsers={allUsers}
        type={modalType}
      />
      <CommentsDialog
        open={dialogState.comments}
        handleClose={handleDialogClose}
        parentId={selectedItem?.id}
        parentType={'raci'}
        api={{ getComments: getCommentsData, createComment: createCommentData }}
      />
      <TimesheetDialog
        open={dialogState.timesheet}
        handleClose={handleDialogClose}
        parentId={selectedItem?.id}
        parentName={selectedItem?.document}
        api={{
          getItems: getTimesheetsData,
          createItem: createTimesheetData,
          updateItem: updateTimesheetData,
          deleteItem: deleteTimesheetData,
        }}
      />
      {/* <ForgeViewerModal
        open={dialogState.preview}
        onClose={handleDialogClose}
        urn={selectedItem?.urn}
      /> */}
      <NewRowModal
        open={newRowModalState}
        handleClose={() => {
          setNewRowModalState(false);
        }}
        allUsers={allUsers}
        docList={documentList}
        handleAddRow={handleSaveRow}
        type={type}
      />
      <NewVersionModal
        raciId={currentRaciId}
        open={newVersionModalState}
        handleClose={() => {
          setNewVersionModalState(false);
        }}
        handleAddVersion={handleCreateVersion}
      />
    </Box>
  );
}
