import React, { createContext, useContext, useState, useEffect } from 'react';
import {
  getProjectRACI,
  createRaci,
  updateRaci,
  deleteRaci,
  addNewVersion,
} from './api';
import {
  getTimesheets,
  createTimesheet,
  updateTimesheet,
  deleteTimesheet,
} from './timesheetApi';
import { getComments, createComment } from './commentsApi';
// import { secondAxios } from '../../../../../../api/axios';
import secondAxios from '../../../../../../common/api/axios';
import { useParams } from 'react-router-dom';
import { getProjectDocumentList } from './Dashboard/docListAPI';
// import { set } from "react-hook-form";
import { useMsal } from '@azure/msal-react';

// Create a context
const DCSContext = createContext();

// Custom hook to use the DCSContext
export const useDCS = () => useContext(DCSContext);

// DCSContext Provider component
export const DCSProvider = ({ children, type }) => {
  const { equipId } = useParams();
  // console.log('iddddddd', equipId);

  const { accounts } = useMsal();
  const username = accounts[0].username;
  // console.log("ttttttttt", username);

  // States to handle data
  const [racis, setRacis] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  // const [sharepointDocuments, setSharepointDocuments] = useState([]);
  // const [urnLoaded, setUrnLoaded] = useState(false);
  const [documentList, setDocList] = useState([]);

  // Fetch data on component mount
  useEffect(() => {
    refreshRaci();
    const getAllUsers = async () => {
      // console.log('type', type);

      const users = (await secondAxios.get('/user')).data;
      //   console.log("uuuuuuuuuuuu", users);

      setAllUsers(users);
    };

    getAllUsers();
    getDocList();
  }, [equipId, type]);
  const getDocList = async () => {
    const list = await getProjectDocumentList(type, equipId);
    setDocList(list);
  };
  // Functions to handle RACI actions
  const addRaci = async (newRaci) => {
    await createRaci(equipId, newRaci);
    refreshRaci();
  };

  const updateRaciData = async (id, updatedRaci) => {
    await updateRaci(id, updatedRaci);
    refreshRaci();
  };

  const deleteRaciData = async (id) => {
    await deleteRaci(id);
    refreshRaci();
  };

  const refreshRaci = async () => {
    if (equipId) {
      const racisFromApi = await getProjectRACI(equipId, type);
      // console.log('raciiisss', racisFromApi);
      setRacis(racisFromApi);
    }
  };

  // Functions to handle Timesheets
  const getTimesheetsData = (id) => getTimesheets(id);
  const createTimesheetData = (id, data) => {
    // console.log('uu', username);
    data.user = username;
    console.log('hereeee', data);
    // createTimesheet(id, data);
  };
  const updateTimesheetData = (id, data) => updateTimesheet(id, data);
  const deleteTimesheetData = (id) => deleteTimesheet(id);

  // Functions to handle Comments
  const getCommentsData = (id) => getComments(id);
  const createCommentData = (data) => createComment(data);

  const addVersion = async (raciId, newVer) => {
    await addNewVersion(raciId, newVer);
    refreshRaci();
  };

  // Provide all states and functions through the context
  return (
    <DCSContext.Provider
      value={{
        racis,
        allUsers,
        documentList,
        addRaci,
        updateRaciData,
        deleteRaciData,
        getTimesheetsData,
        createTimesheetData,
        updateTimesheetData,
        deleteTimesheetData,
        getCommentsData,
        createCommentData,
        type,
        equipId,
        getDocList,
        addVersion,
      }}
    >
      {children}
    </DCSContext.Provider>
  );
};
