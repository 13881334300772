import React, { useEffect, useState, useRef } from 'react';
import {
  Avatar,
  Box,
  Dialog,
  DialogContent,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
  Paper,
  InputBase,
  Divider,
  IconButton,
  CircularProgress,
} from '@mui/material';
import {
  Send as SendIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from '@mui/icons-material';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import {
  fetchComments,
  addComment,
  updateComment,
  deleteComment,
} from '../../../common/service/firebaseService';
import { useMsal } from '@azure/msal-react';

const CommentsDialog = ({ open, handleClose, parentId, parentType }) => {
  const [commentsData, setCommentsData] = useState([]); // Comments list
  const [newComment, setNewComment] = useState(''); // New or edited comment text
  const [editCommentId, setEditCommentId] = useState(null); // Track editing
  const [newAttachment, setNewAttachment] = useState(null); // Attachment file
  const [imagePreview, setImagePreview] = useState(null); // Preview for attachment
  const [loading, setLoading] = useState(false); // Loading state
  const fileInputRef = useRef(null); // File input ref
  const inputRef = useRef(null); // Input for paste handling
  const user = useMsal().accounts[0].name;

  // Fetch comments when the dialog opens or parentId/parentType changes
  useEffect(() => {
    const fetchData = async () => {
      try {
        const comments = await fetchComments(parentId, parentType);
        console.log('comentssss', comments);

        setCommentsData(
          comments.map((comment) => ({
            ...comment,
            timestamp: comment.timestamp?.toDate() || new Date(), // Convert timestamp to JS Date
          })),
        );
      } catch (error) {
        console.error('Error fetching comments:', error);
      }
    };

    if (open) fetchData();
  }, [open, parentId, parentType]);
  // Reset form and states when modal is closed
  useEffect(() => {
    if (!open) {
      resetForm();
    }
  }, [open]);

  // Add or edit a comment
  const handleAddOrEditComment = async () => {
    if (newComment.trim() || newAttachment) {
      setLoading(true);
      try {
        if (editCommentId) {
          // Edit existing comment
          await updateComment(editCommentId, newComment.trim(), newAttachment);
          setCommentsData((prevComments) =>
            prevComments.map((comment) =>
              comment.id === editCommentId
                ? {
                    ...comment,
                    commentText: newComment.trim(),
                    attachment: imagePreview || comment.attachment,
                  }
                : comment,
            ),
          );
        } else {
          // Add new comment
          await addComment(
            parentId,
            parentType,
            newComment.trim(),
            user,
            newAttachment,
          );
          setCommentsData((prevComments) => [
            ...prevComments,
            {
              id: Date.now().toString(), // Temporary ID until re-fetch
              commentText: newComment.trim(),
              attachment: imagePreview,
              userName: user,
              timestamp: new Date(),
            },
          ]);
        }
        resetForm();
      } catch (error) {
        console.error('Error adding or editing comment:', error);
      } finally {
        setLoading(false);
      }
    }
  };

  // Delete a comment
  const handleDeleteComment = async (commentId) => {
    setLoading(true);
    try {
      await deleteComment(commentId);
      setCommentsData((prevComments) =>
        prevComments.filter((comment) => comment.id !== commentId),
      );
    } catch (error) {
      console.error('Error deleting comment:', error);
    } finally {
      setLoading(false);
    }
  };

  // Handle file selection
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setNewAttachment(file);
      setImagePreview(URL.createObjectURL(file)); // Temporary preview
    }
  };

  const handlePaste = (event) => {
    // Get clipboard items
    const items = event.clipboardData?.items;

    if (items) {
      // Iterate through clipboard items
      for (const item of items) {
        if (item.type.startsWith('image/')) {
          // Extract the file
          const file = item.getAsFile();

          if (file) {
            setNewAttachment(file);
            setImagePreview(URL.createObjectURL(file)); // Show the pasted image preview
          }
          break; // Stop after handling the first image
        }
      }
    }
  };

  // Attach paste listener to input
  useEffect(() => {
    const inputElement = inputRef.current;
    if (inputElement) {
      inputElement.addEventListener('paste', handlePaste);
    }
    return () => {
      if (inputElement) {
        inputElement.removeEventListener('paste', handlePaste);
      }
    };
  }, []);

  // Reset form fields
  const resetForm = () => {
    setNewComment('');
    setNewAttachment(null);
    setImagePreview(null);
    setEditCommentId(null);
  };

  return (
    <Dialog open={open} onClose={handleClose} fullWidth>
      <DialogContent onPaste={handlePaste}>
        <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
          <Typography variant="h5" gutterBottom>
            Comments
          </Typography>
          <List>
            {commentsData.length ? (
              commentsData.map((comment) => (
                <React.Fragment key={comment.id}>
                  <ListItem alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar>{comment?.userName?.charAt(0)}</Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <Box>
                          <Typography variant="body1" fontWeight="bold">
                            {comment?.userName || 'Unknown'}
                          </Typography>
                          <Typography variant="caption" color="textSecondary">
                            {new Date(comment.timestamp).toLocaleString()}
                          </Typography>
                        </Box>
                      }
                      secondary={
                        <>
                          <Typography>{comment.commentText}</Typography>
                          {comment.attachment && (
                            <Box mt={1}>
                              <img
                                src={comment.attachment}
                                alt="attachment"
                                style={{
                                  maxWidth: '100%',
                                  borderRadius: '5px',
                                }}
                              />
                            </Box>
                          )}
                        </>
                      }
                    />
                    <IconButton
                      onClick={() =>
                        setEditCommentId(comment.id) ||
                        setNewComment(comment.commentText)
                      }
                    >
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => handleDeleteComment(comment.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </ListItem>
                  <Divider />
                </React.Fragment>
              ))
            ) : (
              <Typography>No comments yet. Add one!</Typography>
            )}
          </List>
          <Box mt={2}>
            {imagePreview && (
              <Box
                mt={2}
                sx={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}
              >
                <Typography variant="body1">Image Preview:</Typography>
                <img
                  src={imagePreview}
                  alt="Preview"
                  style={{ maxWidth: '10%' }}
                />
              </Box>
            )}
            <Paper
              component="form"
              sx={{ p: '2px 4px', display: 'flex', alignItems: 'center' }}
            >
              <InputBase
                ref={inputRef}
                sx={{ ml: 1, flex: 1 }}
                placeholder={editCommentId ? 'Edit comment' : 'Add a comment'}
                value={newComment}
                onChange={(e) => setNewComment(e.target.value)}
                inputProps={{ 'aria-label': 'add comment' }}
                disabled={loading}
              />
              <IconButton
                onClick={() => fileInputRef.current.click()}
                disabled={loading}
              >
                <AddPhotoAlternateIcon />
              </IconButton>
              <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
              <IconButton
                color="primary"
                sx={{ p: '10px' }}
                onClick={handleAddOrEditComment}
                disabled={loading}
              >
                {loading ? (
                  <CircularProgress size={20} color="inherit" />
                ) : (
                  <SendIcon />
                )}
              </IconButton>
            </Paper>
            <input
              type="file"
              accept="image/*"
              ref={fileInputRef}
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
          </Box>
        </Paper>
      </DialogContent>
    </Dialog>
  );
};

export default CommentsDialog;
