import { Box } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import HeadingComponent from '../../../common/components/HeadingComponent';
// import TasklistTab from '../SingleProjectPage/tabs/PunchlistTab';
import { autodesk_axios } from '../../../common/api/axios';
import TaskList from './TaskList';
import { useParams } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useMsal } from '@azure/msal-react';
import { tokenHeader } from '../../../common/api/tokenHeader';
import { AuthContextMSAL } from '../../../common/contexts/mslAuthContext';

const ProjectTaskPage = () => {
  const queryClient = new QueryClient();
  const { accessToken } = useContext(AuthContextMSAL);

  const [project, setProject] = useState(null);
  const { projectId } = useParams();
  useEffect(() => {
    // console.log('heree', projectId, msal);

    const getProject = async () => {
      const project = (
        await autodesk_axios.get(
          `/project/${projectId}`,
          tokenHeader(accessToken),
        )
      ).data;
      console.log('selected project', project.data);
      setProject(project);
    };
    if (accessToken) {
      getProject();
    }
  }, [accessToken, projectId]);
  return (
    <QueryClientProvider client={queryClient}>
      <Box padding={'2rem'}>
        <HeadingComponent heading="Tasks" />
        {project && <TaskList selectedProject={project} />}
      </Box>
    </QueryClientProvider>
  );
};

export default ProjectTaskPage;
