import axios, { autodesk_axios } from '../../common/api/axios';
import User from './interface/userInterface';

export const getUsers = async (accessToken: string): Promise<User[]> => {
  console.log('hereee', accessToken);
  // const response = await autodesk_axios.get('/user');
  if (accessToken) {
    const response = await autodesk_axios.get('/dev/all', {
      headers: { Authorization: `Bearer ${accessToken}` },
    });

    console.log('userss', response);
    return response.data;
  }
  return [];
};

export const createUser = async (user: User): Promise<void> => {
  await axios.post('/user', user);
};

export const updateUser = async (user: User): Promise<void> => {
  await axios.put(`/user/${user.id}`, user);
};

export const deleteUser = async (userId: string): Promise<void> => {
  await axios.delete(`/user/${userId}`);
};
