// import { secondAxios } from "../../../../../../../api/axios";
import secondAxios from '../../../../../../../common/api/axios';
export const getProjectDocumentList = async (type, projectId) => {
  // console.log('projecttt', type);
  const response = (
    await secondAxios.get(`/documents/${projectId}/type/${type}`)
  ).data;
  console.log('dd', response);
  return response;
};

// export const getRACI = async () => {
//   const raciData = await secondAxios.get("/raci");
//   return raciData;
// };
export const updateDocumentList = async (updateDocument, id) => {
  console.log('updated', updateDocument);
  const updateDocumentDto = {
    title: updateDocument.title,
    // department: updateDocument.department,
    docNumber: updateDocument.number,
    responsible: updateDocument.responsible,
  };
  await secondAxios.patch(`/documents/${updateDocument.id}`, updateDocumentDto);
};

export const createDocumentList = async (type, projectId, newDoc) => {
  const resp = await secondAxios.post(`/documents/${projectId}`, {
    type,
    ...newDoc,
  });
};

export const deleteDocumentList = async (id) => {
  try {
    await secondAxios.delete(`/documents/:${id}`);
  } catch (error) {
    console.log(error);
  }
};
